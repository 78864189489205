<template>
<section class="domain_content">
    <!-- Contact Us -->
    <div class="sub-page">
        <h1>Contact Us</h1>

        <p>
            Welcome to our site. If you have questions about our site or the app we provide, please email us. We'll reply to you ASAP.
        </p>
    </div>
</section>
</template>

  
  
<script>
import '@/css/main.scss'
const title = 'Contact Us'
const description = 'GB WhatsApp apk is provided. If you have any questions, feel free to contact us in this site.'
export default {
    metaInfo: {
        title,
        meta: [{
                name: 'description',
                content: description,
            },
            {
                name: 'title',
                content: title,
            },
            {
                property: 'og:title',
                content: title,
            },
            {
                property: 'og:description',
                content: description,
            },
        ],
        "link": [{
            "rel": "canonical",
            "href": "https://gbwhatsapk.chat/about-us/"
        }]
    },
}
</script>
